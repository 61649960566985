@font-face {
    font-family: Georgia;
    src: url('./assets/Fonts/Georgia.ttf');
    font-family: HelveticaNeueCyr-Light;
    src: url('./assets/Fonts/HelveticaNeueCyr-Light.otf');
    font-family: HelveticaNeueCyr-Black;
    src: url('./assets/Fonts/HelveticaNeueCyr-Black.otf');
    font-family: HelveticaNeueCyr-Medium;
    src: url('./assets/Fonts/HelveticaNeueCyr-Medium.otf');
    font-family: HelveticaNeueCyr-Thin;
    src: url('./assets/Fonts/HelveticaNeueCyr-Thin.otf');
}
body {
    margin: 0;
    padding: 0;
    font-family: HelveticaNeueCyr-Thin;
    letter-spacing: 1px;
    // color: white;
    td {
        font-family: HelveticaNeueCyr-Medium;
    }
}
p {
    margin: 5px 0;
}
.PrimaryLayout {
    display: flex;
    width: 100%;
    font-size: 0.9em;
    aside {
        flex: 1;
        min-width: 0;
        min-height: 100vh;
    }
    main {
        flex: 6;
        min-width: 0;
        color: black;
        letter-spacing: 0.5px;
        svg:not([class*='MuiSvgIcon']) {
            color: #343434;
        }
    }
}

@media only screen and (max-width: 1370px) {
    body {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 1280px) {
    body {
        .PrimaryLayout > main {
            width: 70%;
        }
        .PrimaryLayout > aside {
            width: 30%;
        }
    }
}
@media only screen and (max-width: 900px) {
    body {
        .PrimaryLayout {
            display: block;
        }
        .PrimaryLayout > main {
            float: left;
            width: 60rem;
            margin-left: 200px;
            margin-top: -100vh;
            // display: inline-block;
            z-index: 1;
        }
        .PrimaryLayout > aside {
            width: 200px;
            .fixed {
                width: 200px;
                z-index: 10;
            }
        }
    }
}

// убираем голубой бэкграунд для инпутов
// в частности на странице логина
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
    box-shadow: 0 0 0 50px white inset !important;
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    -webkit-text-fill-color: #333 !important;
}


:root {
    --scrollbar-width: 17px;
}