header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-color: #343434;
    padding: 0 3%;
    color: white;
    .sectionName {
        display: inline-block;
    }
    h2 {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
    }
    .buttonUi {
        float: right;
    }
    .active {
        border-bottom: 3px solid rgb(241, 95, 27);
    }
}
