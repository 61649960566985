.fon {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 1;
}

.modalWindow {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 55%;
    background-color: white;
    border-radius: 20px;
    padding: 20px 0;
    top: 25%;
    left: 30%;

    .boolProps {
        width: 90%;
        margin-left: 5%;
        text-align: center;
        //margin-top: 10%;
    }

    .notValid {
        font-size: 0.8em;
        margin: 10px 0;
        color: red;
    }

    .selectComplex {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
        margin-top: 10%;
    }
    .selectFile {
        text-align: center;
    }
    .textProps {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
    }

    .textProps > div {
        margin-top: 10px;
    }
}

#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    margin: 20px;
    padding: 90px 20px;
    .info {
        padding: 10px 0;
    }
}
#drop-area.highlight {
    border-color: rgb(241, 95, 27);
}
.button {
    display: inline-block;
    padding: 10px;
    background: rgb(241, 95, 27);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9em;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    font-weight: bold;
    outline: none;
    margin-right: 10px;
}
.button:hover {
    opacity: 0.7;
}
.button:disabled {
    opacity: 0.7;
    cursor: auto;
}
#fileElem {
    display: none;
}
.my-form {
    position: relative;
}
.my-form span {
    color: rgb(241, 95, 27);
    margin-left: 10px;
}
.my-form .delete-doc {
    color: black;
    margin-left: 3px;
    cursor: pointer;
}
.my-form .delete-doc:hover {
    color: red;
}

.error-message {
    padding: 10px 0;
    color: red;
    position: absolute;
    animation: dissapear 4s;
    opacity: 0;
}
.filesModal {
    z-index: 10000;
    position: fixed;
    background: white;
    width: 50%;
    top: 25%;
    left: 30%;
    height: 55%;
    padding: 20px 0;
    border-radius: 20px;

    .filesModalInner {
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .filesList {
        overflow-y: auto;
        margin-top: 10px;
        .fileItem {
            cursor: pointer;
            padding: 10px;
        }
        .fileItem:hover {
            background: #00000070;
        }
    }
    .buttonUi {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }
}

@keyframes dissapear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
