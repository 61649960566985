.listPage {
    .filterCheckbox {
        display: flex;
        flex-wrap: wrap;
        div > span {
            width: 36px;
            height: 36px;
        }
        p {
            display: inline;
        }
        div {
            padding-right: 12px;
            border: 1.8px solid #f15f1b;
            border-radius: 5px;
            margin: 10px 10px 10px 0;
            user-select: none;
            cursor: pointer;
            span {
                padding: 0;
            }
        }
        #updateComplexes {
            width: 40px;
            height: 40px;
            margin: 10px 0;
            svg {
                color: white;
            }
        }
    }
    th,
    td {
        text-align: left;
        flex-direction: row;
        font-family: HelveticaNeueCyr-Medium;
    }
    th:first-child,
    td:first-child {
        text-align: left;
        padding-right: 0;
    }
    td {
        width: 19%;
    }
    tr {
        cursor: pointer;
    }
    .tableItemCheckbox {
        width: 4%;
    }
}
@media only screen and (max-width: 1370px) {
    .listPage table td {
        font-size: 0.9em !important;
    }
}
