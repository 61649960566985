.communication {
    padding: 20px;
    width: 380px;
    .blocks {
        display: flex;
    }
    .helpText {
        font-size: 0.8em;
    }
    a {
        text-decoration: none;
        color: #4285f4;
        user-select: none;
        cursor: pointer;
    }
    .fileInput {
        display: inline-block;
        margin: 0 20px;
        input[type='file'] {
            display: none;
        }
        .toFile > div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 12px 0;
            padding: 8px 14px;
            // background-color: #f15f1b;
            background-color: rgb(241, 95, 27);
            color: white;
            min-width: 88px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 3px 1px -2px rgba(0, 0, 0, 0.12);
            // width: 120px;
            border-radius: 2px;
            p {
                font-size: 1em;
                line-height: 20px;
                letter-spacing: 1px;
                margin: 0;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 500;
            }
            cursor: pointer;
        }
    }
    .notValid {
        color: red;
    }
    .submitBtn {
        display: flex;
        flex-direction: row-reverse;
    }
}
.communication > div {
    margin: 20px 0;
}
.communication > div:first-child {
    margin: 0;
}
.wrapperForTextEditor {
    max-width: 1200px;
    .rdw-editor-main {
        max-height: 60vh;
    }
}
