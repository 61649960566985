$borderColor: #8c8b8b;
@mixin deleteButton() {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    button {
        width: 40px;
        height: 40px;
        background-color: #f15f1b;
        svg {
            color: white;
        }
    }
}

.EditPage {
    color: black;
    .textProps {
        .map > div {
            width: 100% !important;
            .ymaps-2-1-62-map {
                width: 100% !important;
            }
            img {
                width: 35px;
            }
        }
    }

    .boolProps {
        .phoneNumbers {
            .phoneNumbersItem {
                display: flex;
                align-items: baseline;
                .phoneDeleteButton {
                    @include deleteButton();
                }
            }
        }
        .phoneNumbersItem > div {
            flex: 6;
        }
        .scheduleItem {
            display: flex;
            align-items: center;
            .scheduleDeleteButton {
                @include deleteButton();
            }
        }
        .geolocation {
            p {
                display: inline;
                margin-left: 10px;
            }
        }
    }
    .propsOfArticle > div > div {
        width: 100%;
    }

    .propsOfArticle {
        border: 1px solid $borderColor;
        border-radius: 10px;
        padding: 3%;
        margin-bottom: 20px;
        background-color: white;
    }
    .propsOfArticle > div {
        width: 100%;
        margin: 10px 0;
    }
    .handleValid {
        .notValid {
            margin: 10px 0;
            font-size: 0.9em;
        }
    }
    .notValid {
        color: red;
    }
}
