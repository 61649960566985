.listPage {
    .filterCheckbox {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        div > span {
            width: 36px;
            height: 36px;
        }
        p {
            display: inline;
        }
        div {
            padding-right: 12px;
            border: 1.8px solid #f15f1b;
            border-radius: 5px;
            margin-right: 10px;
            user-select: none;
            cursor: pointer;
        }
    }
    .buttonUi {
        display: flex;
        justify-content: flex-end;
    }
}
@media only screen and (max-width: 1370px) {
    .listPage table td {
        font-size: 0.9em !important;
    }
}
