.LoginPage {
    height: 60vh;
    padding: 20vh 0;
    background-color: #eee;
    color: black;

    .auth-container {
        width: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        background: white;
        border-radius: 2%;
        margin: auto;
        border-radius: 5px;

        .MuiFormControl-root-1 {
            width: 100%;
        }
        .errorAuth {
            font-size: 0.8em;
            color: red;
        }
        .auth-form input[type='text']:required {
            box-shadow: none;
        }

        .auth-title {
            font-size: 36px;
            font-weight: bold;
        }

        .auth-info-text {
            display: flex;
            flex-direction: column;
            color: silver;
            font-size: 14px;
        }

        .auth {
            height: 100vh;
            background: rgb(0, 188, 212);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .auth-phone-back {
            margin-top: 10px;
            text-decoration: none;
            color: black;
        }
    }
}
