.PageForm {
    .ql-editor {
        min-height: 5vw;

        ol,
        ul {
            box-sizing: border-box;
            padding-left: 1.5em;

            li {
                width: 100%;
                margin: 0px;
            }
        }
    }

    .ql-snow .ql-tooltip {
        top: 0px !important;
        left: 10px !important;
        margin-top: 0px !important;
    }

    .ql-container {
        overflow: hidden;
        height: 500px;
    }
}
