aside {
    .fixed {
        position: fixed;
        // background-image: url('../../assets/images/main.png');
        // background-repeat: no-repeat;
        background-color: #313131;
        height: 100vh;
        // ширина с учетом скроллбара, чтобы при скролл локе она не менялась
        width: calc((100vw - var(--scrollbar-width)) * (1 / 7));
    }
    .fixed > div {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    .logo {
        height: 70px;
        // background-image: url('../../assets/images/psnHome_logo.png');
        // background-repeat: no-repeat;
        // background-size: 80px;
        width: 80px;
    }
    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            a {
                display: block;
                text-decoration: none;
                color: white;
                padding: 5%;
            }
            li {
                .logout {
                    padding: 5%;
                    cursor: pointer;
                    p {
                        color: white;
                    }
                }
            }
            .active {
                background-color: #9fa09f;
            }
        }
    }
}
.hidden {
    display: none;
}
