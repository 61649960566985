.buttonUi {
    display: inline-block;
    button div {
        display: flex;
        align-items: center;
    }
    button div > svg {
        margin-left: 5px;
    }

    &:not(:first-child) {
        margin-left: 20px;
    }
}
.buttonUi > button > span {
    font-size: 0.9em;
    letter-spacing: 1px;
}
