$width: 230px;
$height: 155px;

.c-file-upload {
    box-sizing: border-box;

    &--error {
        .c-file-upload-label {
            border-color: #d32f2f;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__native {
        display: none;
    }

    &-label {
        display: grid;
        width: $width;
        height: calc($height - 2px);
        grid-template-rows: 50% 50%;
        justify-items: center;
        align-items: center;
        grid-row-gap: 8px;
        border: solid 1px #ccc;
        cursor: pointer;

        &__top {
            align-self: end;

            &--icon-scaled {
                svg {
                    transform: scale(1.4);
                }
            }
        }

        &__bottom {
            align-self: start;
        }
    }
}

.c-file-upload--error {
    .c-image-upload-label {
        border-color: #d32f2f;
    }
}
