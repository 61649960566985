$borderColor: #8c8b8b;

.createArticle {
    color: black;
    .textProps {
        div .public-DraftStyleDefault-ltr {
            text-align: inherit !important;
        }
    }
    .boolProps {
        .RadioGroup {
            flex-direction: row;
        }
        .ItemOfProps {
            border-bottom: 1px solid $borderColor;
            margin-bottom: 12px;
        }
        .selectComplex {
            display: flex;
            align-items: flex-end;
            b {
                flex: 1;
            }
            .formControl {
                width: 40%;
                flex: 3;
            }
            div {
                white-space: pre-wrap;
            }
        }
        .rdt {
            margin-bottom: 5px;
        }
    }
    .propsOfArticle {
        border: 1px solid $borderColor;
        border-radius: 10px;
        padding: 3%;
        margin-bottom: 20px;
        background-color: white;
        input[type='file'] {
            opacity: 0;
        }
        .images {
            background-size: cover;
            width: 230px;
            height: 155px;

            .funcHover {
                opacity: 0;
                background: rgba(0, 0, 0, 0.7);
                height: 100%;
                color: aliceblue;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                svg {
                    color: aliceblue;
                }
                .copyLink {
                    cursor: pointer;
                    text-align: center;
                }
                .copyLink > button {
                    display: inline-block;
                    background-color: aliceblue;
                    border: none;
                    outline: navajowhite;
                    border-radius: 50%;
                    padding: 10px;
                    margin-top: 5px;
                    span {
                        color: black;
                        cursor: pointer;
                    }
                }
                .delete span {
                    margin: 5px;
                    cursor: pointer;
                }
                label {
                    font-size: 0.85em;
                }
            }
        }
        .images:hover {
            .funcHover {
                opacity: 1;
            }
        }
        .upload-list {
            width: 100%;

            ol {
                padding-left: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 10px;
                list-style-type: none;
                li {
                    margin: 5px;

                    span {
                        margin-left: 7px;
                    }
                }
            }
        }
        .toFile {
            display: block;
            text-align: center;
            cursor: pointer;
            span {
                color: #f15f1b;
            }
        }
    }
    .propsOfArticle > div {
        width: 100%;
    }
    .handleValid {
        .notValid {
            margin-top: 5px;
            font-size: 0.9em;
        }
    }
    .notValid {
        color: red;
    }
    hr {
        border-top: 1px solid $borderColor;
        margin: 2% 0;
    }
}
ul[role='listbox'] svg {
    color: #343434;
}
