$width: 230px;
$height: 155px;

.c-image-card {
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &__hoverable {
        opacity: 0.7;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    &__light-icon {
        color: aliceblue !important;
    }

    &__light-checkbox {
        svg {
            color: aliceblue !important;
            width: 24px;
            height: 24px;
        }
    }

    &__inner {
        opacity: 0;
        width: inherit;
        height: inherit;
        display: grid;
        grid-template-rows: 42px 1fr 42px;
        background-color: rgba(0, 0, 0, 0.7);
        color: aliceblue;
        transition: opacity 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    &__top {
        padding: 9px;

        svg {
            cursor: pointer;
        }
    }

    &__middle {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;

        svg {
            transform: scale(1.4);
            cursor: pointer;
        }
    }

    &__bottom {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 5px;
        align-items: center;
        padding-left: 12px;
        line-height: 1;

        svg {
            cursor: pointer;
        }
    }
}
