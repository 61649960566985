$borderColor: #8c8b8b;

.itemPage {
    color: black;
    span {
        font-weight: 300;
    }
    .textProps {
        pre {
            white-space: normal;
        }
        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 10px;
            list-style-type: none;
            li {
                margin: 5px;
            }
        }
    }

    .boolProps {
        .ItemOfProps {
            border-bottom: 1px solid $borderColor;
            margin-bottom: 12px;
        }
        .dateAndTime {
            display: block;
        }
        p {
            font-weight: bold;
        }
        .propsOfArticle > div {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin: 5px 0;
            p {
                flex: 1;
            }
        }
    }

    .propsOfArticle {
        border: 1px solid $borderColor;
        border-radius: 10px;
        padding: 3%;
        margin-bottom: 20px;
        background-color: white;
    }
    .propsValues {
        flex: 3;
        display: flex;
        flex-wrap: wrap;
    }
    hr {
        border-top: 1px solid $borderColor;
        margin: 2% 0;
    }
    .article {
        width: 100%;
        max-width: 55vw;
        margin: auto;

        p {
            margin: 0;

            img {
                display: block;
                margin: auto;
                width: 100%;
            }
        }
        .ql-align-center {
            text-align: center;
        }
        .ql-align-justify {
            text-align: justify;
        }
        .ql-align-right {
            text-align: right;
        }
        .ql-size-large {
            font-size: 1.5em;
        }
        .ql-size-huge {
            font-size: 2.5em;
        }
        .ql-size-small {
            font-size: 0.75em;
        }
        .ql-font-serif {
            font-family: serif;
        }
        .ql-font-monospace {
            font-family: monospace;
        }
    }
}

@media (max-width: 900px) {
    .itemPage {
        .article {
            width: 100%;
            min-width: 640px;
            margin: 0px;
        }
    }
}
