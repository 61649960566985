$borderColor: #8c8b8b;

.ItemPage {
    color: black;
    justify-content: space-around;
    .textProps {
        margin-right: 20px;
        .map > div {
            width: 100% !important;
            .ymaps-2-1-62-map {
                width: 100% !important;
            }
            img {
                width: 35px;
            }
        }

        span {
            font-weight: 300;
        }
    }

    .boolProps {
    }

    .propsOfArticle {
        border: 1px solid $borderColor;
        border-radius: 10px;
        padding: 3%;
        margin-bottom: 20px;
        background-color: white;
    }
    .propsOfArticle > div {
        width: 100%;
        margin: 10px 0;
    }
}
