.entity-content-with-aside {
    display: grid;
    // padding: 35px 30px 23px 35px;
    grid-template-columns: minmax(0, 1fr) 340px;
    grid-column-gap: 20px;

    &__content {
    }

    &__aside {
    }

    .buttonUi {
        margin-bottom: 20px;
    }
}
